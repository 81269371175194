export const fr = {
    app_name: 'Aerial',
    home: 'Accueil',
    back: 'Retour',
    next: 'Suivant',
    legal_mention: 'Mentions légales',
    delete_dialog: 'Confirmation de suppression',
    delete_confirm: 'Etes-vous sûr de vouloir supprimer {item} ?',
    validation_file_dialog: 'Confirmation de validation',
    validation_file_confirm: 'Etes-vous sûr de vouloir confirmer {item} ?',
    rejection_file_dialog: 'Confirmation de rejet',
    rejection_file_confirm: 'Etes-vous sûr de vouloir rejeter {item} ?',
    field_required: 'Le champ {0} est requis',
    bad_format: "Le format du champ {0} n'est pas correct",
    min_max_length: 'Le champ {0} doit faire entre {1} et {2} caracteres',
    min_max_value: 'Le champ {0} doit être compris entre {1} et {2}',
    max_length: 'Le champ {0} accepte au maximum {1} caractères',
    min_value: 'Le champ {0} doit être supérieur à {1}',
    max_value: 'Le champ {0} doit être inférieur à {1}',
    bad_type: 'Le champ {0} doit être {1}',
    type_integer: 'un nombre entier',
    login_not_found: 'Utilisateur inconnu, merci de vérifier vos identifiants',
    bad_pwd: 'Merci de vérifier votre mot de passe',
    unknow_error: 'Une erreur est survenue',
    sign_in: 'Se connecter',
    pwd_forget: 'Mot de passe oublié ?',
    pwd_forget_simple: 'Mot de passe oublié',
    add: 'Ajouter',
    update: 'Modifier',
    cancel: 'Annuler',
    save: 'Enregistrer',
    import: 'Importer',
    search: 'Rechercher',
    undefined: 'Non défini',
    default_creation_success: 'Enregistrement réalisé',
    default_update_success: 'Mise à jour effectuée',
    default_title_add: 'Création',
    default_title_detail: 'Consultation',
    default_title_edit: 'Edition',
    default_loading_error_message: 'Une Erreur est survenue lors du chargement des informations demandées',
    edit: 'Modifier un courtier',
    details: "Information d'un courtier",
    choose_file: 'Choisir',
    cancel_file: 'Annuler',
    drag_files: 'Déposez des fichiers ici',
    common_error: 'Une erreur est survenue',
    attached_files_title: 'Fichiers attachés',
    generalInfos: 'Informations générales',
    file_confirm_delete: 'Confirmer la suppression du fichier ?',
    add_file: 'ajouter',
    send_mail: 'envoyer',
    valid: 'Valider',
    download: 'Télécharger',
    downloadXlsx: 'Télécharger Excel',
    downloadPdf: 'Télécharger PDF',
    pwd_email_sent: "L'email a été envoyé pour l'utilisateur ",
    link_expired: "Le lien n'est plus valide.",
    password_changed: 'Le mot de passe a bien été modifié.',
    token_expired: 'Le lien est expiré',
    remove_filters: 'Effacer les filtres',
    emailWrongFormat: "Le format de l'email n'est pas correct",
    existing_secondary_drivers_dialog: 'Conducteurs secondaires existants',
    valid_document: 'Valider le document',
    reject_document: 'Refuser le document',
    validated_document: 'Document validé',
    rejected_document: 'Document rejeté',
    waiting_document: 'Document en attente de validation',
    cannot_create_quote: 'Vous ne pouvez pas créer de devis.',
    missing_documents: "Un ou plusieurs documents ne sont pas renseignés sur la page d'informations courtier, ",
    missing_documents_after: ' pour ajouter les documents.',
    click_here: 'Cliquez-ici',
    and: 'Et',
    anyValue: 'Tous',
    error_code: {
        bad_old_password: "Le mot de passe actuel n'est pas correct",
        bad_confirmation_password: 'Erreur dans la confirmation du mot de passe',
        wrong_column_format: 'Le fichier ne correspond pas à celui attendu',
        unpaid_file_already_imported: "Ce fichier d'impayé à déjà été importé",
    },
    user: {
        current: {
            title: 'Mes informations',
        },
        login: 'Identifiant',
        list: 'Utilisateurs',
        details: 'Fiche utilisateur',
        one: 'Utilisateur',
        empty: 'Aucun utilisateur',
        civility: 'Civilité',
        name: 'Nom',
        firstname: 'Prénom',
        lastname: 'Nom',
        password: 'Mot de passe',
        new_password: 'Nouveau mot de passe',
        new_password_confirm: 'Nouveau mot de passe (confirmation)',
        email: 'E-mail',
        phone: 'Téléphone',
        address: 'Adresse',
        address2: "Complément d'adresse",
        postalCode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
        birthday: 'Date de naissance',
        birthCity: 'Ville de naissance',
        birthCountry: 'Pays de naissance',
        birthName: 'Nom de naissance',
        roleId: 'Rôle',
        enabled: 'Actif',
        add: 'Ajouter un utilisateur',
        update: 'Modifier un utilisateur',
        updateThis: 'Modifier',
    },
    subscriberList: {
        products: 'Produits',
    },
    subscriber: {
        listTitle: 'Courtiers',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Email',
        companyEmail: 'Email cabinet',
        productManagerEmail: 'Email du gestionnaire du produit',
        productManagerPhone: 'Téléphone du gestionnaire du produit',
        civility: 'Civilité',
        phone: 'Téléphone',
        companyPhone: 'Téléphone cabinet',
        address: "Adresse du cabinet d'assurance",
        address2: "Complément d'adresse",
        postalCode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
        add: 'Ajouter un courtier',
        edit: 'Modifier un courtier',
        details: "Information d'un courtier",
        updateThis: 'Modifier',
        deleteThis: 'Supprimer',
        companyName: 'Nom du cabinet de courtage',
        companyKbisDoc: 'Kbis',
        companyOriasDoc: 'Attestation Orias',
        companyRcproDoc: 'RCPRO',
        companyRibDoc: 'RIB',
        companyGuarenteeDoc: 'Garantie financière',
        wholesaler: 'Grossiste',
        wholesalerName: 'Nom du Grossiste',
        orias: 'N° Orias',
        legalProtectionNumber: 'Numéro de contrat pour la protection juridique',
        commissionRate: 'Tx Commission (%)',
        siret: 'SIRET',
        siretCity: "Ville d'obtention du SIRET",
        rcs: 'RCS',
        rcsCity: "Ville d'obtention du RCS",
        empty: 'Aucun courtier',
        rcproNumber: 'Numéro RCPRO',
        financialGuaranteeNumber: 'Numéro garantie financière',
        login: 'Identifiant de connexion',
        logo: 'Logo',
        updatePassword: 'Modifier mon mot de passe',
        currentPassword: 'Mot de passe actuel',
        newPassword: 'Nouveau mot de passe',
        confirmNewPassword: 'Confirmer nouveau mot de passe',
        successChangePassword: 'Le mot de passe a été modifié',
        part_society_information: 'Informations du cabinet de courtage',
        part_manager_information: 'Informations du gérant',
        created: 'Créé le',
        disabled: 'Désactivé',
        products: 'Produits du courtier',
        profile: 'Profil du souscripteur',
        confirmDelete: 'Confirmer la suppression du courtier ?',
        addMissingDocs: 'Ajouter les documents manquants',
        brokerList: 'Liste des courtiers',
        brokerListEmpty: 'Aucun courtier',
    },
    quote: {
        listTitle: 'Devis',
        productSelectorTitle: 'Sélection du produit',
        add: 'Ajouter un devis',
        edit: 'Modifier un devis',
        details: 'Détail du devis',
        updateThis: 'Modifier',
        warrantyTitle: 'Sélection des garanties',
        warrantyChoose: 'Garanties choisies',
        warrantyMandatory: 'Garanties obligatoires',
        garageIsHome: "Bureau professionnel dans l'habitation principale",
        garageTel: 'Téléphone garage',
        garageEmail: 'Email garage',
        address: 'Adresse du garage',
        address2: "Complément d'adresse",
        postalCode: 'Code postal',
        city: 'Ville',
        commissionRate: 'Taux Commission',
        uploadDocumentTitle: 'Pièces justificatives',
        summaryTitle: 'Récapitulatif',
        signIt: 'Signer le contrat',
        validateQuote: 'Valider le devis en contrat',
        showContract: 'Consulter le contrat',
        idNumber: 'N°',
        expirationDate: 'Expire le',
        quoteDate: 'Devis du',
        runningGeneration: 'Génération du devis en cours',
        societe: 'Société',
        societeStatus: 'Statut',
        client: 'Gérant',
        garage: 'Garage',
        garageName: 'Nom du garage',
        garageCity: 'Ville du garage',
        garageCA: "Chiffre d'affaires",
        garageSuperficie: 'Superficie',
        customerFirstName: 'Prénom du client',
        customertLastName: 'Nom du client',
        customerIdentity: 'Client',
        validity: 'Validité',
        download: 'Télécharger',
        proofOfAddress: 'Justificatif de domicile',
        kbis: 'Kbis',
        rib: 'RIB',
        startDate: 'Date de début du contrat',
        confirm: 'Confirmer et signer',
        cancel: 'Annuler',
        justificativeDoc: 'Pièces justificatives',
        pricing: 'Tarification',
        quotePart: 'Le devis',
        customerPart: 'Le client',
        brokerPart: 'Cabinet de courtage',
        brokerCompany: 'Nom du cabinet',
        brokerName: 'Courtier',
        email: 'Email',
        phone: 'Téléphone',
        custoLink: 'Client',
        contractLink: 'Contrat',
        mrh: 'Contrat MRH',
        paymentFrequency: 'Echéances',
        quoteValidating: 'Validation du devis',
        paymentDay: 'Prélevé le',
        notEligible: "Les informations fournies ne remplissent pas les critères d'éligibilités",
        requiredPriceStudy:
            "Les informations fournies ne permettent pas d'établir un devis. Une étude de votre dossier sera réaliser. Un conseiller vous recontactera",
        additionalDocuments: 'Documents supplémentaires',
        pjOption: 'Fomule de protection juridique',
    },
    quoteList: {
        idNumber: 'N°',
        expirationDate: 'Expire le',
        company: 'Courtier',
        broker: 'gérant',
        product: 'Produit',
        wholesaler: 'Grossiste',
        empty: 'Aucun devis',
        customerIdentity: 'Gérant',
        garageName: 'Nom du garage',
        customerName: 'Nom du client',
        immatriculationNumber: 'N° immatriculation',
        rcs: 'RCS',
        customerFullName: 'Nom du client',
    },
    contractList: {
        title: 'Contrats',
        idNumber: 'N°',
        broker: 'Courtier',
        company: 'Compagnie',
        wholesaler: 'Grossiste',
        empty: 'Aucun contrat',
        customerLastName: 'Nom du client',
        customerFirstName: 'Prénom du client',
        garageName: 'Nom du garage',
        customerName: 'Nom du client',
        status: 'Statut',
        provisionalSubscription: 'Souscriptions provisoires',
        pendingAmendment: 'Avenant en cours de création',
    },
    quotePriceStudyList: {
        status: 'Statut',
        title: "Demande d'étude RAG lourd",
    },
    customer: {
        listTitle: 'Clients',
        civilStatus: 'État civil du client',
        clientAddress: 'Adresse du client',
        add: 'Ajouter un client',
        edit: 'Modifier un client',
        details: 'Information du client ',
        updateThis: 'Modifier',
        firstName: 'Prénom',
        lastName: 'Nom',
        birthday: 'Date de naissance',
        birthCity: 'Ville de naissance',
        birthCountry: 'Pays de naissance',
        email: 'Email personnel',
        civility: 'Civilité',
        phone: 'Téléphone personnel',
        address: 'Adresse de la société',
        customerAddress: 'Adresse',
        address2: "Complément d'adresse",
        postalCode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
        garageName: 'Nom du garage',
        garageStatus: 'Statut',
        garageCreationDate: 'Date de création',
        garageEffectif: 'Effectif',
        garageCA: "Chiffre d'affaires",
        garageSuperficie: 'Superficie du Garage',
        garageSIRET: 'SIRET',
        birthName: 'Nom de naissance',
        garageAddress: 'Adresse',
        siret: 'Siret',
        RCS: 'RCS',
        RCSCity: "Ville d'obtention du RCS",
        garageRCS: 'RCS',
        garageRCSCity: "Ville d'obtention du RCS",
        companyName: 'Nom de la société',
        companyStatus: 'Statut',
        companyStatusOther: 'Autre',
        garageStatusOther: 'Autre',
        addQuote: 'Nouveau devis',
        brokerName: 'Nom du courtier',
        editRequest: 'Demande de modification',
        sendMailTo: 'Envoyer un email à Aerial',
        cantUpdate: "La modification des données des clients existants n'est pas autorisée.",
        status: 'Statut du souscripteur',
        socioProfessionalClass: 'Classe socio-professionnelle',
        linkWithMainDriver: 'Lien avec le conducteur principal',
        otherLinkWithMainDriver: 'Précision du lien',
        driverAddress: 'Adresse du conducteur',
        driverLicenceDate: "Date d'obtention du permis voiture",
        ueLicence: "Permis délivré par un pays de l'UE",
        currentCRM: 'CRM actuel',
        crm50duration: 'Ancienneté bonus 50',
        addConviction: 'Ajouter une condamnation',
        convictions: 'Condamnations du client',
        convictionType: 'Type de condamnation',
        convictionDate: 'Date de condamnation',
        convictionProofs: 'Pièces justificatives',
        customerTab: 'Client',
        driverTab: 'Conducteurs',
        vehicleTab: 'Véhicules',
    },
    customerList: {
        listTitle: 'Clients',
        empty: 'Aucun client',
        firstName: 'Prénom',
        lastName: 'Nom',
        customerType: 'Type de client',
        customerIdentity: 'Gérant',
        society: 'Société',
        garageName: 'Garage',
        matricule: 'N° client',
        matriculeContract: 'N° contrat',
        matriculeQuote: 'N° devis',
        address: 'Adresse',
        city: 'Ville',
        siret: 'Siret',
        companyName: 'Courtier',
        wholesaler: 'Grossiste',
        contractNumbers: 'Contrats',
        product: 'Produits',
        rcs: 'RCS',
        immatriculationNumber: 'N° immatriculation',
        customerFullName: 'Nom du client',
    },
    driverList: {
        empty: 'Aucun conducteur',
        Secondary: 'Aucun conducteur secondaire',
        birthday: 'Date de naissance',
        lastName: 'Nom',
        firstName: 'Prénom',
        id: 'N°',
    },
    sinister: {
        title: 'Sinistres',
        driverInformations: 'Informations sur le conducteur',
        driverName: 'Nom du conducteur',
        identificationNumber: 'Numéro de contrat',
        empty: 'Aucun sinistre',
        bloodAlcoholTest: "Test d'alcoolémie",
        status: 'Statut',
        responsibility: 'Responsabilité',
        sinisterDate: 'Date',
        sinisterBetween: 'Date des sinistres compris entre',
        sinisterType: 'Type',
        add: 'Ajouter un sinistre',
        edit: 'Modifier un sinistre',
        details: 'Information de sinistre',
        customer: 'Client',
        mainDriver: 'Conducteur principal',
        sinisterProofs: 'Pièces justificatives',
    },
    convictionList: {
        empty: 'Aucune condamnation',
        convictionDate: 'Date',
        convictionType: 'Type',
    },
    LabelTemplate: {
        listTitle: 'Devis',
        add: 'Ajouter un courtier',
        edit: 'Modifier un courtier',
        details: "Information d'un courtier",
        updateThis: 'Modifier',
    },
    contract: {
        listTitle: 'Contrats',
        tab_active: 'Infos/Pièces',
        tab_financial: 'Echéancier',
        tab_documents: 'Documents',
        tab_amendment: 'Avenant en cours',
        tab_amendment_list: 'Historique du contrat',
        CHANGE_SEPA: 'Changement de SEPA',
        CHANGE_WARANTY: 'Changement de garanties',
        CORRECT_SUBSCRIPTION_DATA: 'Corrections des informations',
        add: 'Ajouter un contrat',
        edit: 'Modifier un contrat',
        details: 'Information du contrat',
        signatureTitle: 'Signature du contrat',
        paiementTitle: 'Paiement du contrat',
        updateSepaTitle: 'Changement RIB du contrat ',
        updateThis: 'Modifier',
        startDate: "Prise d'effet",
        signatureDate: 'Date de signature',
        proofOfAddress: 'Justificatif de domicile',
        kbis: 'Kbis',
        rib: 'RIB',
        carteGrise_recto: 'Carte Grise (recto)',
        driverLicence_recto: 'Permis de conduire (recto)',
        carteGrise_verso: 'Carte Grise (verso)',
        driverLicence_verso: 'Permis de conduire (verso)',
        informationReport: "Relevé d'informations",
        mrh: 'Contrat MRH',
        alreadySigned: 'Le contrat est déjà signé',
        alreadyPayed: 'Le contrat est déjà payé',
        contractPayed: 'Le contrat a bien été payé',
        signContract: 'Signer le contrat',
        warrantyTitle: 'Sélection des garanties',
        warrantyChoose: 'Garanties choisies',
        warrantyMandatory: 'Garanties obligatoires',
        garageTel: 'Téléphone garage',
        garageEmail: 'Email garage',
        address: 'Adresse du garage',
        address2: "Complément d'adresse",
        postalCode: 'Code postal',
        city: 'Ville',
        commissionRate: 'Taux Commission',
        uploadDocumentTitle: 'Pièces justificatives',
        summaryTitle: 'Récapitulatif',
        signIt: 'Signer le contrat',
        showContract: 'Consulter le contrat',
        idNumber: 'N°',
        societe: 'Société',
        societeStatus: 'Statut',
        client: 'Gérant',
        garage: 'Garage',
        garageName: 'Nom du garage',
        garageCity: 'Ville du garage',
        garageCA: "Chiffre d'affaires",
        garageSuperficie: 'Superficie du Garage',
        customerFirstName: 'Prénom du client',
        customertLastName: 'Nom du client',
        customerIdentity: 'Client',
        validity: 'Validité',
        download: 'Télécharger',
        confirm: 'Confirmer et signer',
        cancel: 'Annuler',
        justificativeDoc: 'Pièces justificatives',
        signedDocuments: 'Documents signés',
        pricing: 'Tarification',
        contractPart: 'Le contrat',
        customerPart: 'Le client',
        brokerPart: 'Cabinet de courtage',
        brokerCompany: 'Nom du cabinet',
        brokerName: 'Courtier',
        email: 'Email',
        phone: 'Téléphone',
        custoLink: 'Client',
        contractLink: 'Contrat',
        missingDocuments: 'Vous ne pouvez pas payer le contrat si tous les documents ne sont pas renseignés',
        missingBankData: 'Vous ne pouvez pas payer le contrat si les données bancaires ne sont pas renseignées',
        missingDocumentsAndBank:
            'Vous ne pouvez pas payer le contrat si les documents et les données bancaires ne sont pas renseignées',
        pay: 'Payer le contrat',
        sign: 'Signer le contrat',
        bankInformations: 'Informations bancaires',
        addPaymentMode: 'Ajouter les coordonnées bancaires',
        loadBankAccount: 'Chargement des comptes enregistrés',
        iban: 'IBAN',
        bic: 'BIC',
        bankName: 'Banque',
        useExistingBankAccount: 'Utiliser un compte enregistré',
        fillWtihGarageInfos: 'Préremplir avec les informations du garage',
        fillBankWithCustomerData: 'Préremplir avec les information du client',
        addMissingDocs: 'Ajouter les documents manquants',
        addNewBankAccount: 'Ajouter un compte ',
        ownerIdentity: 'Identité du titulaire',
        ownerAddress: 'Addresse du titulaire',
        ownerPostalCode: 'CP du titulaire',
        ownerCity: 'Ville du titulaire',
        savePaymentMode: 'Enregistrer le compte',
        emptyAccountList: 'Aucun compte bancaire enregistré',
        updateSepa: 'Changer le RIB',
        terminateContract: 'Résilier le contrat',
        actions: 'Actions',
        updateInformation: 'Changer les informations',
        addSecondDriver: 'Ajouter un conducteur secondaire',
        removeSecondDriver: 'Supprimer le conducteur secondaire',
        changeSecondDriver: 'Modifier le conducteur secondaire',
        creation: 'Démarrage du contrat',
        forPeriod: 'Pendant la période du',
        at: 'au',
        to: 'à',
        today: "aujourd'hui",
        sendPaymentLink: 'Envoyer un lien de paiement',
        confirmEmail: "Confirmer l'email du client",
        send: 'Envoyer',
        doPayment: 'Procéder au paiement',
        mailSent: "L'email a bien été envoyé",
        signed: 'Contrat Correctement Signé',
        paymentPhrase:
            'Montant comptant à régler à la souscription par Carte bancaire : {0} € TTC pour la période du {1} au {2} dont la taxe attentat de 5,90€ non remboursables.',
        step: 'Étape',
        declareKilometers: 'Déclarer les kilomètres',
        kilometersHistoryList: 'Historique des kilomètres',
        tab_kilometersHistory: 'Historique des kilomètres',
        tab_managment: 'Gestion',
        validateSubscription: 'Valider la soucscription',
        rejectSubscription: 'Rejecter la soucscription',
        emptyKilometerHistoryList: 'Aucun historique de kilométrage',
        signSepa: 'Signer le manda sepa',
        additionalDocuments: 'Documents supplémentaires',
        commissions: 'Commissions',
        validation_contract_title: 'Validation du contrat',
        rejection_contract_title: 'Rejet de la souscription',
        rejection_contract_confirm: 'Etes-vous sûr de vouloir rejeter cette souscription ?',
        validation_contract_confirm: 'Etes-vous sûr de vouloir confirmer ce contrat ?',
        cant_validate_subscription: 'Impossible de valider la souscription',
        addSinister: 'Déclarer un sinistre',
        sinisterSuccessMessage: 'Sinistre ajouté',
    },
    financialTransaction: {
        amount: 'Montant TTC',
        period: 'Période',
        state: 'Statut',
        paymentMode: 'Type',
        transactionDate: 'Date de paiement',
        type: 'Type',
        empty: 'Aucune transaction financière',
        number: 'Nombre de prélèvements',
        markAsPayed: 'Marquer payée',
        productName: 'Produit',
    },
    contractCommission: {
        dateStart: 'Mois',
        empty: 'Aucune commission',
        broker: 'courtier',
        value: 'Montant',
    },
    contractSignature: {
        signManualy: 'Signer manuellement le contrat',
        manualFile: 'Contrat signé manuellement',
        confirmManualSignature: 'Confirmer la signature manuelle',
        runningGeneration: 'Génération de la signature electronique',
        downloadContract: 'Télécharger le contrat à signer',
        downloadAndSign: "Veuillez télécharger le contrat et le déposer après l'avoir signé manuellement",
    },
    contact: {
        details: "Information d'un courtier",
        updateThis: 'Modifier',
        civility: 'Civilité',
        lastname: 'Nom',
        firstname: 'Prénom',
        email: 'E-mail',
        phone: 'Téléphone',
        address: 'Adresse',
        address2: "Complément d'adresse",
        city: 'Ville',
        postalCode: 'Code postal',
        country: 'Pays',
        wholesaler: 'Grossiste',
        companyTitle: 'Entreprise',
        commissionRate: 'Taux Commission',
        edit: 'Modifier la fiche courtier',
        company: {
            name: 'Nom de la société de courtage',
            commission: 'Commission',
        },
    },
    garage: {
        listTitle: 'Garages',
        add: 'Ajouter un garage',
        edit: 'Modifier un garage',
        details: 'Information du garage',
        alertSuperficy:
            'Si votre garage a une superficie supérieure à 350m², vous ne pouvez pas avoir la garantie Multi-Risque Professionnelle',
        updateThis: 'Modifier',
        editRequest: 'Demande de modification',
        sendMailTo: 'Envoyer un email à Aerial',
        cantUpdate: "La modification des données des garages existants n'est pas autorisée.",
    },
    garageSelector: {
        title: 'Selection du garage',
        new: 'Nouveau Garage',
        existing: 'Garage Existant',
        name: 'Nom',
        siret: 'SIRET',
        address: 'Adresse',
        postalCode: 'Code Postal',
        city: 'Ville',
        empty: 'Aucun garage existant',
        rcs: 'RCS',
    },
    garageList: {
        empty: 'Aucun garage',
    },
    documentList: {
        title: 'Documents',
        miscDocuments: 'Documents divers',
    },
    signatureResult: {
        success: 'La signature a été effectuée avec succès',
        fail: 'La signature a échouée',
        cancel: 'La signature a été annulée',
    },
    serverErrorCode: {
        no_initial_transaction: "Aucun paiement n'a été enregistré pour ce contrat",
        unknowError: 'Une erreur inconnue est survenue',
        no_unpaid_to_regularize: 'Aucun impayé à régulariser',
        contract_required_document_not_validate: 'Un ou plusieurs document(s) ne sont pas validé(s)',
        contract_required_document_not_found: 'Un ou plusieurs document(s) ne sont pas présent(s)',
    },
    inputErrorCode: {
        phoneLength: "Le format du numéro de téléphone n'est pas valide",
        postalCodeLength: 'Le champ doit être composé de 5 chiffres',
        oriasNumberLength: 'Le champ doit être composé de 8 chiffres',
        siretNumberLength: 'Le champ doit être composé de 14 chiffres',
        rcsNumberLength: 'Le numéro RCS doit être composé de 9 chiffres',
        alphanumeric: 'Le champ doit être composé uniquement de lettres, apostrophes, et tirets',
        numeric: 'Le champ doit être composé uniquement de chiffres',
        bicNumberLength: 'Le champ doit faire 8 ou 11 caractères',
        ibanNumberLength: 'Le champ doit faire entre 27 et 34 caractères',
        dateYear: 'Le champ doit être composé de 4 chiffres',
        caSize: 'Le CA doit être inférieur ou égal à 500 000€',
        superficySize: 'La surface doit être inférieure ou égale à 350m²',
        crm: 'Le CRM doit être compris entre 50 et 250',
        areaNumber: 'Le champ doit être en entier positif ou est trop grand',
        priceNumber: 'Le champ doit être en entier positif ou est trop grand',
        akFreeInputLength: 'Le champ est trop long',
        rateNumber: 'Le champ est un taux dont la valeur attendue est comprise entre 0 et 100',
    },
    dashboard: {
        title: 'Tableau de bord',
        financialTitle: 'Financier',
        all: 'Tous',
        brokerAndWholesaler: 'Tous les courtiers / grossistes',
        contractFooter:
            'Aucun en attente de paiement/signature | Et {n} en attente de paiement/signature | Et {n} en attente de paiement/signature',
        quoteFooter:
            'Aucun expirant dans moins de 5 jours | Dont {n} expirant dans moins de 5 jours | Dont {n} expirants dans moins de 5 jours',
        sinisterFooter: 'Aucun en cours de traitement | Et {n} en cours de traitement | Et {n} en cours de traitement',
        activeQuote: 'Devis en cours',
        activeContract: 'Contrats en cours',
        provisionnalSubscription: 'Nouvelles souscriptions provisoires',
        inLateProvisionnalSubscription: 'Souscriptions provisoires de plus de 48 heures',
        refusalSubscription: 'Souscriptions provisoires refusées',
        provisionnalAmendment: 'Avenant à valider',
        refusalAmendment: 'Avenant Refusés',
        provisionnalFooter:
            'Aucune en cours de traitement | Et {n} en cours de traitement | Et {n} en cours de traitement',
        inLateProvisionnalFooter:
            'Aucun avenant en retard de traitement | Et {n} avenant de plus de 48 heures | Et {n} avenants de plus de 48 heures',
        newSinisters: 'Nouveaux sinistres',
        reminderSubscription: 'Relance à traiter',
        contractTermination: 'Demande de résiliations',
        new_cbd_quote: 'Nouveau devis CBD',
    },
    bankExport: {
        exportDate: "Date d'export",
        downloadTooltip: "Contient les exports jusqu'au {0} qui n'ont jamais été exportés.",
        download: 'Télécharger un nouvel export',
        title: 'Exports banque',
        list: 'Liste des exports existants',
        downloadDate: 'Date de téléchargement',
    },
    financialDashboard: {
        title: 'Borderaux de reversement',
        unpaidTab: 'Impayés',
        addUnpaid: 'Saisir un impayé',
        createUnpaid: 'Créer un impayé',
        unpaidFees: 'Frais de gestion',
        sendRelaunchMail: 'Envoyer un mail de relance',
        unpaidRelaunchDest: 'Destinataire de la relance',
        unpaidDest: 'Destinataire du mail de paiement',
        importUnpaid: 'Importer des impayés',
        exportUnpaid: 'Exporter les impayés',
        file: "Fichier d'impayés",
        sumUnpaid: 'Total',
        nbEntries: 'Nombre',
        invalidFile: " Ce fichier de prélèvement n'est pas valide.",
        send: 'Envoyer',
    },
    unpaidList: {
        emptyList: 'Aucun impayé',
        unpaidDate: 'Date',
        contractNumber: 'N° de contrat',
        productName: 'Produit',
        fees: 'Frais',
        amount: 'Montant',
    },
    transactionList: {
        title: 'Échéanciers',
        emptyList: 'Aucun échéancier',
        type: 'Type',
        paymentMode: 'Mode de paiement',
        transactionDate: 'date de transaction',
        amount: 'Montant',
        startDate: 'Début',
        endDate: 'Fin',
        bankExportDate: 'Export de la banque',
        paymentFrequency: 'Fréquence de paiement',
        identificationNumber: 'Numéro de contrat',
        productName: 'Produit',
        status: 'Statut',
        transactionBetween: 'Date de transaction comprise entre ',
    },
    contractRegularization: {
        title: 'Régularisaton du contrat',
        unpaidAmount: 'Montant des impayés',
        noUnpaid: 'Aucun impayé à régulariser pour ce contrat',
        unpaidNumber: "Nombre d'impayés",
        regularizeAllUnpaid: 'Régulariser tous les impayés',
        regularizePartialUnpaid: 'Régulariser un seul impayé',
        payAnOther: 'Effectuer un autre règlement',
    },
    companyExportList: {
        title: 'Exports compagnie',
    },
    message: {
        title: 'Messages',
        list: 'Liste des messages',
        emptyList: 'Aucun message',
        add: 'Ajouter un message',
        titleItem: 'Titre du message',
        type: 'Type',
        published: 'Publié continuellement',
        pubStartDate: 'Début de publication',
        pubEndDate: 'Fin de publication',
        products: 'Produits concernés par le message',
        placeholder: 'Contenu du message...',
        edit: 'Modifier un message',
        details: 'Information du message',
        updateThis: 'Modifier',
        files: 'Pièces jointes',
    },
    messageList: {
        empty: 'Aucun message',
        title: 'Liste des messages',
        messageTitle: 'Titre du message',
        pubStartDate: 'Date de début publication',
        pubEndDate: 'Date de fin publication',
        published: 'Publié',
        type: 'Type',
        info: 'Info',
        warning: 'Avertissement',
        alert: 'Alerte',
        products: 'Produits',
        noProducts: 'Aucun produit',
        from: 'Du',
        to: 'au',
    },
    productList: {
        empty: 'Aucun produit',
        noAvailable: 'Aucun produit disponible',
        title: 'Liste des produits',
        name: 'Nom',
        enabled: 'Activé',
    },
    product: {
        edit: 'Modifier un produit',
        title: 'Produit',
        titleItem: 'Titre du produit',
        marketingStart: 'Date de début du produit',
        marketingEnd: 'Date de fin du produit',
        enabled: 'Produit activé',
        shortCode: 'Code court',
        contractYearIncrement: 'Incrément annuel de contrat',
        quoteYearIncrement: 'Incrément annuel de devis',
        contractMatriculeIncrement: 'Incrément de matricule de contrat',
        quoteMatriculeIncrement: 'Incrément de matricule de devis',
        increments: 'Incréments',
        product: 'Le produit',
        updateThis: 'Modifier',
        details: 'Information du produit',
        bankInformations: 'Informations bancaires',
        iban: 'IBAN',
        bic: 'BIC',
        bankName: 'Banque',
    },
    administration: {
        title: 'Administration',
        vehicleListFile: 'Fichier des véhicules (format CSV)',
        importLoading: "Import en cours... Changer de page n'arrêtera pas le processus",
        importText: 'Déposez ci-dessous un nouveau fichier pour actualiser la liste des véhicules',
    },
    driver: {
        add: 'Ajouter un conducteur',
        addPrincipal: 'Ajouter un conducteur principal',
        addSecondary: 'Ajouter un conducteur secondaire',
        edit: 'Modifier un conducteur',
        title: 'Conducteurs',
        civilStatus: 'État civil du conducteur',
        address: 'Adresse du conducteur',
        additionalDetails: 'Informations complémentaires',
        removeSecondary: 'Supprimer le conducteur secondaire',
    },
    vehicle: {
        add: 'Ajouter un véhicule',
        edit: 'Modifier un véhicule',
        details: 'Information du véhicule',
        emptyList: 'Aucun véhicule',
        usualParking: 'Lieu de stationnement habituel',
        garageIsPostalCodeHabitation: "Lieu de stationnement a le même code postal que l'habitation",
        usage: 'Usage du véhicule',
        frenchImmatriculation: 'Immatriculation française',
        modified: 'Véhicule modifié (puissance)',
        documentationAtSubscriberName: 'Carte grise au nom du souscripteur ou conjoint',
        leasing: "Carte grise au nom d'une société de leasing et au nom de l'assuré",
        orgNameVisible: 'Nom de la société visible',
        driverNameVisible: 'Nom du conducteur visible',
        waitingImmatriculation: "En attente d'immatriculation",
        boughtAbroad: "Acheté à l'étranger",
        immatriculationDate: "Date d'immatriculation",
        immatriculationNumber: "Numéro d'immatriculation",
        chassisNumber: 'Numéro de chassis',
        insuredBetweenPurchaseAndSubscription: "Assuré entre l'achat et aujourd'hui",
        category: 'Catégorie du véhicule',
        purchaseDate: "Date d'achat",
        purchaseType: "Mode d'acquisition",
        mileagePackage: 'Forfait kilométrique souhaité',
        kilometers: 'Kilométrage actuel',
        brand: 'Marque',
        model: 'Modèle',
        version: 'Version',
        typeMines: 'Type mines',
        productionPeriod: 'Période de production',
        carr: 'Carrosserie',
        nbPlace: 'Nombre de places',
        box: 'Boîte de vitesse',
        powerF: 'Puissance fiscale',
        selected: 'Sélectionné',
        nature: 'Nature du véhicule',
        immatriculationCertificate: "Certificat d'immatriculation",
        selection: 'Sélection du véhicule',
        today: "Aujourd'hui",
        fullName: 'Vehicule',
        kilometerHistoryValue: 'Kilomètres relevés',
        kilometerHistoryDate: 'Date du relevé',
        marketStart: 'Date de début de vente',
        marketEnd: 'Date de fin de vente',
    },
    antecedents: {
        details: 'Information des antécédents',
        during36lastMonths: 'Le souscripteur au cours des 36 derniers mois',
        sinisters36lastMonths: 'Sinistres au cours des 36 derniers mois',
        convictions36lastMonths: 'Condamnations au cours des 60 derniers mois',
        title: 'Antécédents',
        add: 'Ajouter des antécédents',
        addMain: 'Ajouter les antécédents du conducteur principal',
        addSecondary: 'Ajouter les antécédents du conducteur secondaire',
        edit: 'Modifier des antécédents',
        assured: 'A été assuré',
        nbMonthAssured: 'Nombre de mois assuré',
        canceled: 'A eu un contrat résilié par un assureur',
        sinisterType: 'Type',
        sinisterDate: 'Date',
        convictionType: 'Type',
        convictionDate: 'Date',
        responsability: 'Responsabilité',
        bloodAlcoholTest: "Controle d'alcoolémie",
        addSinister: 'Ajouter un sinistre',
        noSinister: 'Aucun sinistre',
        noConviction: 'Aucune condamnation',
    },
    contractOptions: {
        details: 'Options du contrat',
        edit: 'Modifier les options du contrat',
        options: 'Options',
        title: 'Options de contrat',
        add: 'Ajouter des options de contrat',
        cancelCurrent: "Résilier l'assurance actuelle dans le cadre de la loi Hamon",
        rct: 'Option "RC tractage remorque de plus de 750kg"',
        trailerImmat: 'Immatriculation de la remorque',
        gpc: 'Option "Garantie protection du conducteur"',
        eoa: 'Option "Effets, objets et accessoires"',
        assistance: 'Option "Assistance"',
        paymentFrequency: 'Fractionnement du paiement',
        paymentDay: 'Date de prélèvement souhaitée',
        oldCompanyName: 'Nom de la compagnie précédente',
        oldPoliceNumber: 'Numéro de la police précédente',
    },
    equiteQuoteForm: {
        formula: 'Formules',
        chooseFormula: 'Souscrire à cette formule',
    },
    address: {
        label: 'Adresse',
        numberAndStreet: 'N° et nom de rue',
        zipCode: 'Code postal',
        city: 'Ville',
        country: 'Pays',
    },
    cbd: {
        listTitle: 'Devis CBD',
        title: 'Tarificateur Châteaux et Belles Demeures',
        fields: {
            quote: {
                label: 'Devis',
                location: 'Fait à',
                date: 'Le',
                info: "Le proposant certifie que les déclarations ci-dessus et qui doivent servir à l'établissement du contrat sont à sa connaissance exactes. Toute omission, toute déclaration fausse ou inexacte, pourrait entraîner la nullité du contrat ou exposer l'Assuré à supporter la charge de tout ou partie des indemnités (articles L 113-8 et L 113-9 du Code des Assurances). RCS de Paris 420 795 668 - Enregistré à l'ORIAS sous le numéro 13 007 385 (www.orias.fr). Garantie Financière et assurance de responsabilité Civile Professionnelle conformes au code des assurances. AERIAL Assurances est soumis au contrôle de l'Autorité de Contrôle Prudentiel et de Résolution, sise 4 place de Budapest - CS 92459 - 75436 Paris Cedex 9. En cas de réclamation, contactez l'agence au numéro ci-dessus, ou écrivez un email à reclamation{'@'}aerial-assurances.com. Si toutefois un différend persiste, vous pouvez saisir le médiateur de l'Assurance à l'adresse TSA 50110 - 75 441 Paris Cedex 09 ou sur le site : https://www.mediation-assurance.org/",
                index: {
                    label: 'Ventilation de la prime',
                    type: "Type d'indice applicable",
                    types: {
                        ffb: 'FFB',
                        ri: 'RI',
                    },
                    amount: "Montant de l'indice à la souscription (en €)",
                },
            },
            middleman: {
                label: 'Courtier en assurance',
                name: 'Nom du cabinet',
                contact: {
                    label: 'Coordonnées',
                    tel: 'N° téléphone',
                    email: 'Adresse électronique',
                },
                address: {
                    numberAndStreet: 'N° et nom de rue',
                    zipCode: 'Code postal',
                    city: 'Ville',
                    country: 'Pays',
                },
            },
            policyholder: {
                label: "Preneur d'assurance",
                types: {
                    label: "Type de preneur d'assurance",
                    individual: 'Particulier',
                    company: 'Professionnel',
                },
                individual: {
                    civility: 'Civilité',
                    firstname: 'Prénom',
                    lastname: 'Nom',
                },
                company: {
                    label: 'Entreprise',
                    name: 'Nom de la société',
                    legalStatus: 'Forme juridique',
                    legalStatusTypes: {
                        micro_entreprise: 'Micro-entreprise',
                        entreprise_individuelle: 'Entreprise individuelle',
                        eurl: 'EURL',
                        sarl: 'SARL',
                        sa: 'SA',
                        sas: 'SAS',
                        sasu: 'SASU',
                        association: 'Association',
                        sci: 'SCI',
                        snc: 'SNC',
                    },
                    siret: 'N° SIRET',
                    commercialActivity: 'Activité commerciale',
                    legalRepresentative: {
                        label: 'Représentant légal',
                        civility: 'Civilité',
                        firstname: 'Prénom',
                        lastname: 'Nom',
                        quality: 'Qualité du représentant',
                    },
                },
                contact: {
                    label: 'Coordonnées',
                    tel: 'N° téléphone',
                    email: 'Adresse électronique',
                },
                address: {
                    numberAndStreet: 'N° et nom de rue',
                    zipCode: 'Code postal',
                    city: 'Ville',
                    country: 'Pays',
                },
            },
            insuredsite: {
                label: 'Site à assurer',
                otherImportantInformations: 'Informations importantes relatives au site assuré',
                address: {
                    numberAndStreet: 'N° et nom de rue',
                    zipCode: 'Code postal',
                    city: 'Ville',
                    country: 'Pays',
                },
            },
            risks: {
                label: 'Descriptif et caractéristiques du risque',
                proposerQuality: 'Qualité du proposant',
                vacancyDuration: "Durée d'inhabitation maxi par an (en jours)",
                propertyType: 'Type de bien',
            },
            precisions: {
                label: 'Précisions',
                historicalOrInventoryMonument: "Classé aux monuments historiques ou à l'inventaire",
                constructionYear: 'Année de construction',
                stateLevel: 'Etat général',
                constructionAndRoofMaterials: 'Matériaux de construction et de couverture (au moins 90 % en dur)',
                heatingType: 'Mode de chauffage',
                environment: 'Environnement',
                materiality: 'Matérialité',
                occupationRate: "Taux d'occupation (en %)",
                adjoiningBuilding: 'Bâtiment contigu',
            },
            work: {
                label: 'Travaux',
                planified: {
                    label: 'Travaux en cours ou planifiés',
                    startDate: 'Début des travaux',
                    duration: 'Durée (en jours)',
                    nature: 'Nature',
                    cost: 'Coût (en €)',
                },
                precedent: {
                    label: 'Derniers travaux réalisés',
                    roof: 'Entretien ou étanchéité des toitures',
                    electricalInstallation: "Mise en conformité de l'installation électrique",
                    facade: 'Entretien ou rénovation des façades',
                    const: {
                        minus: 'Moins de {0} ans',
                        between: 'Entre {0} et {1} ans',
                        more: 'Plus de {0} ans',
                    },
                },
            },
            usage: {
                label: 'Usage',
                residenceTypes: {
                    main: 'Résidence principale',
                    secondary: 'Résidence secondaire',
                },
                rental: {
                    label: 'Mise à disposition ou location ?',
                    level: {
                        label: 'À quelle fréquence ?',
                        partial: 'Partielle',
                        total: 'En totalité',
                        punctually: 'Ponctuelle',
                        yearRound: "À l'année",
                    },
                    tenant: {
                        label: 'À qui ?',
                        individual: 'Particuliers',
                        company: 'Entreprises',
                    },
                    forFree: 'À titre ?',
                    titles: {
                        forFree: 'Gracieux',
                        notForFree: 'Onéreux',
                    },
                    activity: {
                        label: 'Activités proposées',
                        wedding: 'Mariages',
                        gite: "Gîtes ou chambres d'hôtes",
                        guidedTour: 'Visites guidées',
                        seminar: 'Séminaires',
                        culturalEvent: 'Évènements culturels (concerts, expositions, réceptions)',
                        museum: 'Musée',
                    },
                },
            },
            area: {
                label: 'Superficies',
                info: "Nous entendons par surface totale déclarée, la surface totale additionnée de tous les niveaux des locaux à usage professionnel et ou privé (y compris dépendances, garages, ou parking fermé, cave, sous-sol, grenier combles utilisés ou non) en tenant compte de l'épaisseur des murs extérieurs ; Sont intégrés dans cette surface les locaux à usage privé et à usage des préposés, ainsi que la superficie des piscines en dur et enterrées, leurs abris sur support fixé situés dans l'enceinte de l'établissement. Les toitures terrasses ne sont pas à prendre en compte.",
                numberOfBuildings: 'Nombre de bâtiments',
                totalSurface: 'Surface totale (en m²)',
                numberOfLevels: 'Nombre de niveaux (sous-sols et greniers compris)',
                mainBuildingSurface: 'Surface de Bâtiment Principal Hors dépendances (en m²)',
                habitableOutbuildingsSurface: 'Surface de dépendances habitables (en m²)',
                nonHabitableOutbuildingsSurface: 'Surface de dépendances non habitables (en m²)',
                parkSurface: 'Superficie du parc (en m²)',
                outside: {
                    label: 'Extérieur',
                    indoorSwimmingPool: 'Piscine intérieure',
                    outdoorSwimmingPool: 'Piscine extérieure',
                    waterPlan: "Plan d'eau",
                    agriculturalExploitation: "Présence d'une exploitation agricole sur la propriété",
                    tennis: 'Tennis',
                    fireHydrant: 'Borne incendie à proximité',
                },
                sum_error:
                    "La surface totale ({0} m²) n'est pas égale à la somme des surfaces du bâtiment principal et des dépendances ({1} m² + {2} m² + {3} m²) ",
            },
            prevention: {
                label: 'Prévention',
                intrusionAlarm: 'Alarme anti-intrusion',
                guarding: 'Gardiennage',
                safe: 'Coffre-fort',
                accessDoor: {
                    label: "Porte d'accès",
                    armored: 'Blindée',
                    full: 'Pleine',
                    threeLocksPoint: 'Munies de serrures 3 points ou plus',
                },
                extinguisher: 'Extincteurs',
                surgeArrester: 'Parafoudre',
                lightningConductor: 'Paratonnerre',
                fireDetection: 'Système de détection incendie relié à un centre de télésurveillance',
                smokeDetector: 'Détecteur de fumée (minimum 3 par étage) reliés à la société de télésurveillance',
                distanceFireStation: 'Distance du centre de secours le plus proche (pompiers) en km',
                squatters: 'Site dépourvu de squatteurs et de toutes personnes qui occupent les locaux illégalement',
                exclusions: 'Exclusions spécifiques',
                smokeForbidden: 'Interdiction de fumer',
                closedSite: 'Site entièrement clos',
            },
            capital: {
                label: 'Capitaux',
                realEstate: {
                    label: 'Biens immobiliers',
                    value: 'Valeur du bâtiment (en €)',
                },
                movableProperty: {
                    label: 'Biens mobiliers',
                    value: 'Valeur totale (en valeur déclarée en €)',
                    preciousValue: 'Dont objets de valeurs ou Précieux Capital garanti (en €)',
                    expertise: 'Une expertise a-t-elle été réalisée ?',
                },
            },
            background: {
                label: 'Antécédents',
                insuredLastYears: 'Le risque a-t-il été assuré au cours des 5 dernières années ?',
                previousCompany: "Précédente compagnie d'assurance",
                terminatedBy: {
                    label: 'Résilié par',
                    policyholder: "L'assuré",
                    insurer: 'La compagnie',
                },
                reason: 'Au motif de',
                policyNumber: 'N° de police',
                damageLast36Months: {
                    label: 'Sinistres subis au cours des 36 derniers mois',
                    info: "Veuillez saisir le nombre de sinistres et pour chacun d'entre eux la nature, la date et le coût des dommages",
                },
            },
            guarantees: {
                label: 'Garanties',
                info: 'Pour chaque garantie, merci de bien vouloir préciser les capitaux/LCI demandés',
                fireBuilding: 'Incendie bâtiment (en €)',
                fireMerchandise: 'Dont Marchandise (en €)',
                fireContent: 'Dont Contenu (en €)',
                max_error: 'Le montant de la guarantie {1} ne peut pas être supérieur au montant de la garantie {0}',
                storm: 'Tempête, neige, grêle (en €)',
                naturalDisaster: 'Catastrophes naturelles (en €)',
                terrorism: 'Attentats et actes de terrorisme (en €)',
                electricity: 'Dommages électriques (en €)',
                waterDamages: 'Dégâts des eaux (en €)',
                glass: 'Bris de glace (en €)',
                vol: 'Vol (en €)',
                vandalism: 'Vandalisme (en €)',
                exceptContent: " Contenu d'exception (en €) ",
                effondrement: 'Effondrement (en €)',
                machine: 'Bris de machines (en €)',
                liquideLost: 'Pertes de liquides (en €)',
                denreesLost: 'Pertes de denrées (en €)',
                costsAndLosses: 'Frais et pertes',
                costsAndLossesAmount: 'Montant des frais et pertes (en €)',
                lossesExploitation: " Pertes d'exploitation (en €)",
                lossesFonds: 'Pertes de valeur du fonds de commerce (en €)',
                civilResponsibilityOwner:
                    "Responsabilité civile propriétaire d'immeuble (corporel, matériel et immatériel) (en €)",
                tenantsClaims: 'Recours des locataires ou occupants (en €)',
                neighboursClaims: 'Recours des voisins et des tiers (en €)',
                operatingLiability: " Responsabilité civile d'exploitation (en €)",
                employerLiability:
                    " Responsabilité civile de l'employeur à l'égard des préposés, y compris la faute inexcusable de l'employeur (en €)",
                professionalLiability: 'Responsabilité civile professionnelle (en €)',
            },
            modalities: {
                label: 'Modalités',
                lciAmount: 'Montant de LCI générale souhaitée (en €)',
                franchiseAmount: {
                    label: 'Montant de franchise souhaitée (en €)',
                    500: '500 €',
                    1000: '1 000 €',
                    2000: '2 000 €',
                    3500: '3 500 €',
                    5000: '5 000 €',
                    7500: '7 500 €',
                    10000: '10 000 €',
                },
                softFactor: 'SoftFactor',
                desiredEffectiveDate: "Date d'effet souhaitée",
                paymentFrequency: {
                    label: 'Paiement',
                    annual: 'Annuel',
                    semester: 'Semestriel',
                    quarter: 'Trimestriel',
                },
            },
        },
        search: {
            customer: {
                lastname: 'Nom du client',
                firstname: 'Prénom du client',
                fullname: 'Client',
            },
            expiration: {
                modification: 'Etat du devis',
                modificationDate: 'Date limite de modification',
                validation: 'Statut du devis',
                validationDate: 'Validité du devis',
            },
            quote: {
                number: 'N°',
                numberQuote: 'N° devis',
            },
        },
        save: {
            quote: {
                label: 'Enregistrer le devis',
                errors: {
                    unknow: 'Une erreur est survenue lors de la sauvegarde du devis',
                    fields: 'Veuillez vérifier les champs du devis',
                },
                success: {
                    title: 'Devis enregistré',
                    detail: 'Votre devis a bien été enregistré sous le numéro {0}.',
                },
            },
            contract: {
                label: 'Envoyer',
                button: 'Demander la validation du devis en contrat',
                warning: 'Voulez-vous vraiment convertir ce devis en contrat ? Cette action est irréversible.',
                info: 'La demande sera transmise au service souscription AERIAL pour validation.',
                confirmation: 'Envoyer au service souscription AERIAL pour validation du contrat',
                errors: {
                    unknow: 'Une erreur est survenue lors de la sauvegarde du contrat',
                },
                success: {
                    title: 'Contrat enregistré',
                    detail: 'Votre demande a bien été transmise pour validation auprès du service souscription AERIAL sous la référence {0}',
                },
            },
        },
        edit: 'Modifier les informations',
        actions: 'Gérer le devis',
        generate: 'Télécharger le devis',
        calculate: 'Calculer les primes',
        next: 'Suivant',
        previous: 'Précédent',
    },
    vehicleList: {
        brand: 'Marque',
        model: 'Modèle',
        existingVehicles: 'Véhicules existants',
        immatriculationNumber: "Numéro d'immatriculation",
    },
    contractTermination: {
        add: 'Demande de résiliation',
        resiliationDate: 'Date de la résiliation',
        motif: 'Motif',
        otherMotif: 'Autre motif',
        validationComment: 'Commentaire de validation',
        resiliationAskDate: 'Date de la demande de résiliation',
        attachments: 'Pièces jointes',
        validationState: 'Statut',
        validationStateYes: 'Accepter',
        validationStateNo: 'Rejeter',
        finalizeValidationFormTitle: 'Validation de la demande de résiliation',
    },
    contractTermiationList: {
        title: 'Demandes de résiliations',
        empty: 'Aucune résiliation',
        id: 'N°',
        contractIdentifier: 'N° de contrat',
        motif: 'Motif',
        validationState: 'Validation',
        terminationDate: 'Date de résiliation',
        askDate: 'Date de la demande',
    },
    contractTerminationForm: {
        add: 'Saisir une demande de résiliation',
        edit: 'Modifier une demande de résiliation',
        details: "Détail d'une demande de résiliation",
    },
    veos: {
        send: 'Envoyer dans Veos',
        sync: 'Synchroniser avec Veos',
        sent: 'Envoyé dans Veos',
        fetch: 'Récupérer depuis Veos',
        fetched: 'Données récupérées depuis Veos',
        data: 'Informations Veos',
    },
    enums: {
        bloodAlcoholTest: {
            POSITIVE: 'Positif',
            NEGATIVE: 'Négatif',
        },
        documentType: {
            CONTRACT_RIB: 'RIB',
            CONTRACT_KBIS: 'KBIS',
            CONTRACT_PROOF_OF_ADDRESS: 'Justificatif de domicile',
            CONTRACT_MRH: 'MRH',
            CONTRACT_MISC: 'Autres documents',
            MANUALY_SIGN_CONTRACT: 'Documents signés manuellement',
            PRODUCT_INSURANCE_CERT: "Attestation d'assurance",
            PRODUCT_CG: 'CG',
            PRODUCT_IPIDS: 'IPIDS',
            PRODUCT_EMPTY_SEPA: 'Mandat SEPA vierge',
            PRODUCT_GREEN_CARD: 'carte verte provisoire',
            PRODUCT_GREEN_CARD_DEFINITIVE: 'carte verte définitive',
            SIGNED_DOCUMENT: 'Documents signés',
            CONTRACT_CARTE_GRISE_RECTO: 'Carte grise (recto)',
            CONTRACT_DRIVER_LICENCE_RECTO: 'Permis de conduire (recto)',
            CONTRACT_DRIVER_LICENCE_VERSO: 'Permis de conduire (verso)',
            CONTRACT_INFORMATIONS_REPORT: "Relevé d'informations",
            DISPO_GENERAL: 'Disposition générales',
            PRODUCT_MISC: 'Divers',
            CONVENTION_EUROPASSIST: 'Convention EuropAssistance',
            GARANTIE_CONDUCTEUR: 'Garantie Conducteur',
        },
        contractStatus: {
            SETUP: 'Mise en place',
            READY: 'Avant cours',
            ACTIF: 'En cours',
            CANCEL: 'Annulé',
            TERMINATE: 'Résilié',
            SUSPEND: 'Suspendu',
        },
        contractTerminationMotif: {
            OTHER: 'Autre',
            WITHDRAWAL_LICENCE: 'Retrait de permis',
            REFUSAL_SUBSCRIPTION: 'Rejet de souscription',
            HAMON: 'Loi Hamon',
            MAIN_ECHEANCE: 'Echéance principale du contrat',
            SALE_VEHICLE: 'Cession du véhicule',
            CHANGE_INSURED_SITUATION: "Changement de situation de l'assuré",
            INCREASE_FRANCHISE_DECREASE_WARRANTY: 'Changement hausse de franchise ou baisse de plafond de garanties',
            DICREASE_RISK: 'Diminution du risque sans diminution de prime',
            // :"Résiliation par nous d'un autre de l'assuré après sinistre",
            CHANGE_PRICE: 'Modification du tarif entrainant une majoration de cotisation',
            UNPAID: "Non paiement des cotisations de l'assuré",
            INCREASE_RISK: 'Aggravation du risque',
            BAD_DECLARATION: 'Omission ou déclaration inexacte sans mauvaise foi',
            AFTER_SINISTER: 'Après sinistre',
            INSURED_DEATH: "Décès de l'assuré",
            LOSS_VEHICLE: 'Perte totale du véhicule assuré',
            VEHICLE_REQUISITION: 'Réquisition du véhicule',
        },
        convictionType: {
            DEFAULT_INSURANCE: "Défaut d'assurance",
            HIT_AND_RUN: 'Délit de fuite',
            REFUSAL_COMPLY: "Refus d'obtempérer",
            POSITIVE_STUP: "Conduite sous l'emprise de stupéfiants",
            POSITIVE_ALCOOL: "Contrôle positif d'alcoolémie",
            SUSPENDED_LICENCE: 'Suspension de permis de conduire',
            CANCELED_LICENCE: 'Annulation de permis de conduire',
        },
        crm50_duration: {
            LESS_ONE_YEAR: "Moins d'un an",
            ONE_YEAR: '1 an',
            TWO_YEARS: '2 ans',
            THREE_YEARS: '3 ans',
            FOUR_YEARS: '4 ans',
            FIVE_YEARS_AND_MORE: '5 ans et plus',
        },
        financialTransactionState: {
            WAITING: 'En attente',
            IN_PROGRESS: 'Exporté banque',
            SECOND_TRY: 'Exporté banque (2ème présentation)',
            TO_TREAT: 'A traiter',
            CANCEL: 'Annulée',
            UNPAID: 'Impayée',
            PAYED: 'Payée',
        },
        monthAssuredType: {
            12: '12 mois',
            24: '24 mois',
            36: '36 mois',
        },
        paymentFrequencyType: {
            MONTHLY: 'Mensuel',
            QUARTERLY: 'Trimestriel',
            YEARLY: 'Annuel',
        },
        responsibilityType: {
            YES: 'Responsabilité totale',
            PARTIAL: 'Responsabilité partielle',
            NO: 'Non responsable',
        },
        secondaryDriverLink: {
            CJT: 'Conjoint',
            PAR: 'Parent',
            ENF: 'Enfant',
            OTHER: 'Autre',
        },
        sinisterStatus: {
            NEW: 'Nouveau',
            IN_PROGRESS: 'En cours de traitement',
            CLOSED: 'Clos',
        },
        sinisterType: {
            MATERIEL: 'Matériel',
            CORPOREL: 'Corporel',
            DOMMAGE: 'Dommage',
            BRIS_GLACE: 'Bris de glace',
        },
        spcType: {
            AGR: 'Agriculteur',
            ART: 'Artisan',
            COM: 'Commerçant',
            ETU: 'Étudiant',
            FON: 'Fonctionnaire ou assimilés',
            LIB: 'Profession libérale',
            RET: 'Retraité',
            SSE: 'Salarié sédentaire',
            DIR: 'Dirigeant',
            SP: 'Sans profession',
            SI: 'Salarié itinérant',
        },
        statusType: {
            ANP: 'Artisan en nom propre',
            CNP: 'Commerçant en nom propre',
            PART: 'Particulier',
        },
        usualParkingType: {
            BIF: 'Box individuel fermé',
            JPC: 'Jardin privé clos',
            PPC: 'Parking privé collectif fermé et couvert',
            VP: 'Voie publique / parking public',
        },
        validationState: {
            WAITING: 'En attente',
            VALIDATED: 'Validé',
            REJECTED: 'Rejeté',
        },
        yesNo: {
            true: 'Oui',
            false: 'Non',
        },
        quotePriceStudyState: {
            NEW: 'Nouveau',
            IN_PROGRESS: 'En cours',
            DONE: 'Traité',
        },
        subscriptionReminderState: {
            DONE: 'Faite',
            TO_SEND: 'A envoyer',
            PLANNED: 'Planifiée',
        },
        revivalStatus: {
            NONE: 'aucune',
            FIRST_REVIVAL: '1ère relance envoyée',
            SECOND_REVIVAL: '2ème relance envoyée',
        },
    },
    provisionalSubscriptionList: {
        title: 'Souscriptions provisoires',
        empty: 'Aucune souscription',
        dateEffect: "Date d'effet",
        contractNumber: 'N°',
        customerFirstName: 'Prénom du client',
        customerLastName: 'Nom du client',
        broker: 'Courtier',
        stateRevival: 'Etat de relance',
        status: 'Statut',
        creationDate: 'Date de dépôt',
        dueDate: 'Date de fin des 30 jours',
    },
    subscriptionReminder: {
        modalAddTitle: 'Ajouter une relance',
        plannedDate: 'Date prévue',
    },
    provisionalSubscriptionStatus: {
        TO_BE_PROCESSED: 'À traiter',
        REJECTED: 'Rejetée',
        PENDING: 'En cours de traitement',
        INCOMPLETE: 'Incomplète',
        TO_REDUCE: 'À relancer',
        CANCEL: 'Résiliée',
        VALIDATED: 'Validée',
        WITHOUT_EFFECT: 'Sans effet',
    },
    reminderSubscriptionList: {
        title: 'Relances',
    },
};
