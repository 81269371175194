import {library} from '@fortawesome/fontawesome-svg-core';

import {
    faUserSecret,
    faFileContract,
    faFileSignature,
    faHome,
    faUser,
    faUserTie,
    faCheck,
    faShare,
    faSave,
    faTimes,
    faImage,
    faBan,
    faFilePdf,
    faFileExport,
    faExclamationTriangle,
    faFolderOpen,
    faFileDownload,
    faSignOutAlt,
    faEdit,
    faDownload,
    faFileArrowDown,
    faFileExcel,
    faPlus,
    faSpinner,
    faCalculator,
    faEye,
    faCertificate,
    faFile,
    faMoneyCheckDollar,
    faEllipsisVertical,
    faPen,
    faEuroSign,
    faUpRightFromSquare,
    faBuildingColumns,
    faEnvelope,
    faBox,
    faScrewdriverWrench,
    faAddressCard,
    faCar,
    faList,
    faCircleInfo,
    faWarehouse,
    faCircleMinus,
    faTrash,
    faFileImport,
    faBookmark,
    faFileCircleXmark,
    faFolderTree,
    faFileLines,
    faClock,
    faHourglass,
    faFileCsv,
    faCheckDouble,
    faHandPointer,
    faPaperPlane,
    faFolderPlus,
    faRepeat,
    faBug,
} from '@fortawesome/free-solid-svg-icons';

import {
    faCircleCheck as farCircleCheck,
    faCircleXmark as farCircleXmark,
    faCircleQuestion as farCircleQuestion,
    faClock as farClock,
    faUser as farUser,
    faFileImage,
} from '@fortawesome/free-regular-svg-icons';

export default function registerFAIcon() {
    library.add(
        faFileLines,
        faUserSecret,
        faHourglass,
        faFileContract,
        faSpinner,
        faCalculator,
        faClock,
        faPaperPlane,
        faBug,
        farClock,
        faCheck,
        faShare,
        faRepeat,
        faHandPointer,
        faFolderPlus,
        farUser,
        faFileCsv,
        faSave,
        faTimes,
        faImage,
        faCheckDouble,
        faBan,
        faFilePdf,
        faPlus,
        faFileSignature,
        faHome,
        faUser,
        faUserTie,
        faSignOutAlt,
        faEdit,
        faDownload,
        faEye,
        faFileArrowDown,
        faFileExcel,
        faFileImport,
        faEnvelope,
        faBox,
        faFile,
        faMoneyCheckDollar,
        faFileExport,
        faExclamationTriangle,
        faFolderOpen,
        faFileDownload,
        faCertificate,
        faEllipsisVertical,
        faPen,
        faEuroSign,
        faUpRightFromSquare,
        faBuildingColumns,
        faScrewdriverWrench,
        faAddressCard,
        faCar,
        faList,
        faCircleInfo,
        farCircleCheck,
        faWarehouse,
        faCircleMinus,
        faCar,
        faTrash,
        faCircleInfo,
        farCircleXmark,
        farCircleQuestion,
        faBookmark,
        faFileCircleXmark,
        faFolderTree,
        faCircleInfo
    );
}
