<script>
import store from '@store';
import route from '@router';
import loaderMixin from "@mixins/loaderMixin";
import randomRef from "@mixins/randomRef";
import ProgressSpinner from 'primevue/progressspinner';
import UserService from '@services/userService'
import Toast from 'primevue/toast';
    export default {
        mixins: [randomRef,loaderMixin],
        components: {ProgressSpinner,Toast },
         data() {
      return {
        intialize : true
      };
    },
    mounted() {
      if(!route?.currentRoute?.meta?.authRequired){
          this.intialize= false;
      }
      else if ( store.getters['loggedIn']) {
          UserService.userCurrent()
            .finally(() => {
              this.intialize= false;

            });           
      }else {
         this.intialize= false;
         this.$router.push({name: 'login', query: {redirectFrom: route.currentRoute.value.fullPath}});
      }
    },
    };
</script>

<template>
    <metainfo>
        <template v-slot:title="{content}">
            {{ content ? this.$i18n.t(content) + ` | ` + this.$i18n.t('app_name') : this.$i18n.t('app_name') }}
        </template>
    </metainfo>
    <Toast />
    <template v-if="intialize">
     <div class="row justify-content-center align-items-center h-50-vh" style="padding-top:15rem">
            <ProgressSpinner style="width:300px;height:300px" strokeWidth="1"  />
      </div>
    </template>
    <template v-else>
    <RouterView />
    </template>
    
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
    // Allow element/type selectors, because this is global CSS.
    // stylelint-disable selector-max-type, selector-class-pattern

    // Normalize default styles across browsers,
    // https://necolas.github.io/normalize.css/
    $aerial-blue: #002c5b;
    $aerial-orange: #f59331; // remplace   #2196F3;
    $aerial-orange-dark: #ee8924; // remplace   #0d89ec
    $aerial-orange-light: #f5933182; //remplace #0b7ad1
    $aerial-orange-light-1: #f5933182; //remplace #0b7ad1
    $aerial-orange-light-2: rgba(245, 147, 49, 0.322);
    $aerial-orange-light-3: rgba(245, 147, 49, 0.13);
    @import '~normalize.css/normalize.css';
    //@import '~primevue/resources/themes/saga-blue/theme.css';

    @import '~primevue/resources/primevue.min.css';
    @import '~primeicons/primeicons.css';

    // Design variables and utilities from src/design.
    @import '@design';
</style>
