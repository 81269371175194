import {canSeeRoute, currentUserHasRole, isCurrentCompanyDisabled, isAllowedTo} from '@utils';
import {AERIAL, BROKER, WHOLESALER, CELLINKS} from '@utils/roles';
export default {
    mixins: [],
    setup() {
        return {};
    },
    mounted() {},
    data() {
        return {AERIAL, BROKER, WHOLESALER, CELLINKS};
    },
    computed: {
        isAerial() {
            return currentUserHasRole(AERIAL);
        },
        isWholesaler() {
            return currentUserHasRole(WHOLESALER);
        },
        isCellinks() {
            return currentUserHasRole(CELLINKS);
        },
    },

    methods: {
        canSeeRoute,
        currentUserHasRole,
        isCurrentCompanyDisabled,
        isAllowedTo,
    },
};
