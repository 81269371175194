import {createApp} from 'vue';
import {createMetaManager, plugin as vueMetaPlugin} from 'vue-meta';
import {createPinia} from 'pinia';
import App from './App.vue';
import router from './router';
import store from './store';
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'metismenu';
import globalMixin from '@mixins/globalMixin';

/**PrimeVue**/
import Tooltip from 'primevue/tooltip';
import PrimeVue from 'primevue/config';

/**LIB**/
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VuelidatePlugin from '@vuelidate/core';
import CKEditor from '@ckeditor/ckeditor5-vue';

// import {FontAwesomeIcon, FontAwesomeLayersText, FontAwesomeLayers} from '@fortawesome/vue-fontawesome';
import {i18n} from '@utils/i18n';
import Ripple from 'primevue/ripple';
import registerGlobalComponents from './globalComponentRegistry';
import ToastService from 'primevue/toastservice';

// Conserver cet import qui ajoute
import registerFAIcon from './fontAwesomeImportRegistry';
registerFAIcon();
const pinia = createPinia();

const appVue = createApp(App)
    .use(store)
    .use(i18n)
    .use(createMetaManager())
    .use(vueMetaPlugin)
    .use(router)
    .use(VueLoading)
    .use(VuelidatePlugin)
    // .use(require('vue-moment'), {moment})
    .use(PrimeVue, {
        ripple: true,
        locale: {
            dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
            monthNames: [
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Aout',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre',
            ],
            monthNamesShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: "Aujourd'hui",
            weekHeader: 'Wk',
            firstDayOfWeek: 1,
            dateFormat: 'dd/mm/yy',
        },
    })
    .use(CKEditor);

appVue.use(pinia);

registerGlobalComponents(appVue);

appVue.directive('ripple', Ripple);
appVue.directive('tooltip', Tooltip);

appVue.directive('aktooltip', {
    created: function (el, binding) {
        let triggerClick = binding.modifiers.click;

        if (triggerClick) {
            let hvrFunction = function (event) {
                binding.instance.$refs[binding.value].toggle(event);
            };
            el.__vHoverOver__ = hvrFunction;
            el.addEventListener('click', hvrFunction);
        } else {
            let hvrFunction = function (event) {
                // binding.instance.$refs.appenChild(instance.$el);
                binding.instance.$refs[binding.value].show(event);
                // instance.show(event);
            };
            let hideFunction = function (event) {
                binding.instance.$refs[binding.value].hide(event);
            };

            // el.__vHoverOver__ = binding.value.over || (() => {})
            // el.__vHoverLeave__ = binding.value.leave || (() => {})
            el.__vHoverOver__ = hvrFunction;
            el.__vHoverLeave__ = hideFunction;

            // // Add Event Listeners
            el.addEventListener('mouseover', hvrFunction);
            el.addEventListener('mouseleave', hideFunction);
        }
    },
    unmounted: function (el, binding) {
        let triggerClick = binding.click;

        if (triggerClick) {
            let hvrFunction = function (event) {
                binding.instance.$refs[binding.value].toggle(event);
            };
            el.__vHoverOver__ = hvrFunction;
            el.addEventListener('click', hvrFunction);
        } else {
            // Remove Event Listeners
            el.removeEventListener('mouseover', el.__vHoverOver__);
            el.removeEventListener('mouseleave', el.__vHoverLeave__);
            delete el.__vHoverOver__;
            delete el.__vHoverLeave__;
        }
    },
});
appVue.use(ToastService);
appVue.mixin(globalMixin);
appVue.mount('#app');
appVue.config.unwrapInjectedRef = true;

// appVue.config.errorHandler = (err, instance, info) => {
//     console.error('ERROR!!!', err);
// };
