import $ from 'jquery';
// import jQuery from 'jquery'
// import ApexCharts from 'apexcharts'
// import moment from 'moment'

// Inclusion des sous modules utilitaires
export * from './userRightUtils';
export * from './dateUtils';

/*************************
 App Header
 *************************/
export function appheader() {
    $('.mobile-toggle').on('click', function () {
        $('body').toggleClass('sidebar-toggled');
    });

    $(document).on('click', '.mega-menu.dropdown-menu', function (a) {
        a.stopPropagation();
    });

    $('.sidebar-toggle').on('click', function () {
        $('body').toggleClass('sidebar-mini');
        $('.app-navbar').toggleClass('expand');
    });
}

/*************************
 SidebarNav
 *************************/
export function sidebarNav() {
    var sidebarNav = $('.sidebar-nav');
    if (sidebarNav.length > 0) {
        (function (window, document, $) {
            $(function () {
                if (sidebarNav.length > 0) {
                    $('#sidebarNav').metisMenu();
                }
            });
        })(window, document, $);
    }
}

/*************************
 SidebarNav
 *************************/
export function appNavBar() {
    $('.app-navbar').hover(function () {
        if ($('body').hasClass('sidebar-mini')) $('.app-wrap').toggleClass('expand-navbar');
    });
}

/**********
 * Remove Empty params
 ***********/
export function removeEmptyParams(params) {
    if (!params) return params;
    for (const key of Object.keys(params)) {
        if (params[key] === '') {
            delete params[key];
        }
    }
    return params;
}

/********
 * Go back
 ********/
export function goBack(router) {
    if (window.history.length > 2) {
        router.go(-1);
    } else {
        router.push('/');
    }
}

/********
 * Back to
 ********/
export function goBackTo(router, route) {
    router.push({name: route});
}
export function createFormData(obj, files) {
    let formData = new FormData();
    formData.append(
        'item',
        new Blob([JSON.stringify(obj)], {
            type: 'application/json',
        })
    );
    Array.from(files).forEach((f) => {
        formData.append(f.name, f);
    });
    return formData;
}
