import store from '@store';
import router from '@router';
import {acls} from '@utils/aclUtils';
import ProductService from "@services/productService";

export function getRouteExpectedRole(routeName) {
    //  if(this.$router.hasRoute(routeName)) return null;
    let matchedRoute = router.getRoutes().find((route) => {
        return route.name == routeName;
    });
    if (!matchedRoute || !matchedRoute?.meta?.roles) {
        return null;
    }
    return matchedRoute.meta.roles;
}
export function getRouteExpectedProduct(routeName) {
    let matchedRoute = router.getRoutes().find((route) => {
        return route.name == routeName;
    });

    if (!matchedRoute || !matchedRoute?.meta?.products) {
        return null;
    }
    return matchedRoute.meta.products;
}
export function canSeeRoute(routeName) {
    let expectedRole = getRouteExpectedRole(routeName);
    let expectedProduct = getRouteExpectedProduct(routeName);
    if (!expectedRole && !expectedProduct) return true;
    let isAllowed = false;
    if (expectedRole) {
        expectedRole.forEach((role) => {
            let currentUserRole = store.getters['currentUserAuthorities'];
            if (currentUserRole.includes(role)) isAllowed = true;
        });
        if (!isAllowed) return false;
    }

    if (expectedProduct) {
        isAllowed = false;
        let currentUserProducts = store.getters['currentUserProducts'];
        let currentUserProductCode = currentUserProducts.map((userProduct) => userProduct.shortCode);

        expectedProduct.forEach((products) => {
            if (currentUserProductCode.includes(products)) isAllowed = true;
        });
        if (!isAllowed) return false;
    }
    return isAllowed;
}

export function currentUserHasRole(...roles) {
    let hasRole = false;
    roles.forEach((role) => {
        let currentUserRole = store.getters['currentUserAuthorities'];
        if (currentUserRole.includes(role)) hasRole = true;
    });
    return hasRole;
}
export function currentUserHasProduct(...products) {
    let hasRole = false;
    let currentUserProducts = store.getters['currentUserProducts'];
    if (!currentUserHasProduct) return false;
    let currentUserProductCode = currentUserProducts.map((userProduct) => userProduct.shortCode);
    products.forEach((product) => {
        if (currentUserProductCode.includes(product)) hasRole = true;
    });
    return hasRole;
}

export function isCurrentCompanyDisabled() {
    if (store?.state?.userInformation?.disabled) {
        return true;
    }
    return false;
}

export async function isProductEnabled(productCode) {
    let enabled = false;
    await ProductService.getByProductCode(productCode).then((product) => {
        if (product !== undefined && product.enabled) {
            enabled = true;
        }
    })
    return enabled;
}

export function currentUserCanSeeProduct(productId) {
    let companyAccesses = store?.state?.userInformation?.companyAccesses;
    if (null != companyAccesses) {
        return companyAccesses.includes(productId);
    }
    return null;
}

export function isAerial() {
    return currentUserHasRole('ROLE_AERIAL');
}
export function isAllowedTo(p_sACL) {
    let expectedRole = acls[p_sACL];
    if (null == expectedRole) {
        console.error('No defined acl for [' + p_sACL + ']');
        return false;
    }
    let isAllowed = false;
    let currentUserRole = store.getters['currentUserAuthorities'];

    if (expectedRole instanceof Array) {
        expectedRole.forEach((role) => {
            if (currentUserRole.includes(role)) isAllowed = true;
        });
    } else if (null != expectedRole.roles) {
        expectedRole = expectedRole.roles;
        expectedRole.forEach((role) => {
            if (currentUserRole.includes(role)) isAllowed = true;
        });
        if (true == expectedRole.onlyActiveAccount && isCurrentCompanyDisabled) {
            isAllowed = false;
        }
    } else {
        console.error('unknow acl format for [' + p_sACL + ']', expectedRole);
        return false;
    }

    return isAllowed;
}
