import axios from 'axios';
import store from '@store';
import router from '@router';

export default (secure = true) => {
    let header = {};
    header['Accept'] = 'application/json';
    header['Content-Type'] = 'application/json';
    if (secure) {
        let user = store.state.currentUser;
        if (user && user.token) header['Authorization'] = 'token=' + user.token;
    }

    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
        isRefreshing = false;
        failedQueue.forEach((prom) => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const instance = axios.create({
        baseURL: secure ? process.env.VUE_APP_BASE_API_URL : process.env.VUE_APP_PUBLIC_API_URL,
        withCredentials: false,
        headers: header,
    });
    instance.interceptors.response.use(
        function (response) {
            store.dispatch('setAPPVersion', response.headers['app-version']);
            return response;
        },
        function (error) {
            if (null != error?.response?.headers['app-version']) {
                store.dispatch('setAPPVersion', error.response.headers['app-version']);
            }

            if (error?.response?.status == 403) {
                router.push({name: 'unauthorized'});
            } else if (error?.response?.status == 401) {
                if (error.response?.data?.message == 'token_expired') {
                    let originalRequest = error.config;

                    if (error.response.status === 401 && !originalRequest._retry) {
                        if (isRefreshing) {
                            return new Promise(function (resolve, reject) {
                                failedQueue.push({resolve, reject});
                            })
                                .then(() => {
                                    // originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                    let user = store.state.currentUser;
                                    if (user && user.token) {
                                        originalRequest.headers['Authorization'] = 'token=' + user.token;
                                    }
                                    return axios(originalRequest);
                                })
                                .catch((error) => {
                                    return Promise.reject(error);
                                });
                        } else if (null != store?.state?.userInformation?.refreshToken) {
                            originalRequest._retry = true;
                            isRefreshing = true;

                            return new Promise(function (resolve, reject) {
                                store
                                    .dispatch('refresh', {
                                        refreshToken: store.state.userInformation.refreshToken,
                                        login: store.state.currentUser.login,
                                    })
                                    // axios
                                    //     .post('/rest/public/refreshToken', {
                                    //         refreshToken:  store.state.userInformation.refreshToken})
                                    .then(() => {
                                        let user = store.state.currentUser;
                                        if (user && user.token) {
                                            axios.defaults.headers.common['Authorization'] = 'token=' + user.token;
                                            originalRequest.headers['Authorization'] = 'token=' + user.token;
                                        }
                                        //header['Authorization'] = 'token=' + user.token;

                                        processQueue(null, user.token);
                                        resolve(axios(originalRequest));
                                    })
                                    .catch((err) => {
                                        store.dispatch('logOutExpired');
                                        //processQueue(err, null);
                                        // store.dispatch(showMessage({ message: 'Expired Token' }));
                                        // cons
                                        reject(err);
                                    })
                                    .then(() => {
                                        isRefreshing = false;
                                    });
                            });
                        }
                    }
                    return Promise.reject(error);
                }
                store.dispatch('logOutExpired');
            } else if (!error.status) {
                // FIXME : affichage d'un message d'erreur à la place ?
                // store.dispatch('logOutExpired');
            }

            return Promise.reject(error);
        }
    );
    return instance;
};
