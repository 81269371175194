import moment from 'moment';

export function formatDate(date, pattern) {
    let usedPattern = null != pattern ? pattern : 'DD/MM/YYYY';
    return null != date ? moment(date).format(usedPattern) : null;
}

export function formatDateTime(date) {
    return null != date ? moment(date).format('DD/MM/YYYY HH:mm') : null;
}
