import Api from "@/services/api";

export default {
    users() {
        return Api().get("/users").then(res => res.data);
    },
    user(userId) {
        return Api().get("/user/" + userId).then(res => res.data);
    },
    userCurrent() {
        return Api().get("/user/current").then(res => res.data);
    },
    create(user) {
        return Api().post("/user", user).then(res => res.data);
    },
    update(user) {
        return Api().put("/user/" + user.userId, user).then(res => res.data);
    },
    delete(user) {
        return Api().delete("/user/" + user.userId).then(() => user);
    },
    updateCurrentUserPassword(changePasswordDto){
        return Api().post("/users/current/password", changePasswordDto).then(res => res.data);
    },
    pwdForget(login) {
        return Api(false).post("/passwordForget",{login:login});
    },
    pwdReset(user, jwt) {
        return Api(false).post("/passwordReset", user,  {headers : {"authorization" : jwt}});
    },
    resetTokenValid(userId, token) {
        return Api(false).post("/user/resetToken/valid/" + userId, token).then(res => res.data);
    },
    userByResetToken(token){
        return Api().get("/user/resetToken" + token.then(res => res.data));
    }

}
